import * as React from "react"
import type { HeadFC } from "gatsby"
import "normalize.css"

import "../styles/global.css"
import { InsubHeader } from "../components/InsubHeader"
import { AboutBanner } from "../components/AboutBanner"
import { Footer } from "../components/Footer"
import { ContactForm } from "../components/ContactForm"

const ContactPage = () => {

  return (
    <>
      <InsubHeader />
      <ContactForm />
      <AboutBanner />
      <Footer />
    </>
  )
}

export default ContactPage

export const Head: HeadFC = () => <title>Insub Audio - Event Production - Contact</title>
