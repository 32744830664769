import React, { FC, useState } from "react"
import styled from "@emotion/styled"

const FORM_ENDPOINT = "https://qqnnl4ietxvwaomusf52ayhmn40aqgmb.lambda-url.eu-west-1.on.aws/";

const Container = styled.div`
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const NameContainer = styled.div`
  margin-bottom: 20px;
`;

const PhoneAndEmailContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const Submit = styled.button`
  padding: 0;
  background: none;
  border: 1px solid black;
  border-radius: 10px;
  color: black;
  font-size: 24px;
  padding: 10px;
  height: 50px;
  cursor: pointer;
  text-decoration: none;
  line-height: normal;
  height: 50px;
  box-sizing: border-box;
`

const Text = styled.p`
  font-size: 24px;
`

const NameInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
`;

const EmailInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
`;

const PhoneInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
`;

const MessageBox = styled.textarea`
  resize: none;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 5px 10px;
`;

const PhoneContainer = styled.div`
  
`;

const EmailContainer = styled.div`
  
`;

export const ContactForm: FC = () => {

  const [submitted, setSubmitted] = useState(false);


  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    console.log(e.target);

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'no-cors',
      body: JSON.stringify({
        name: 'Liam Davies',
        email: 'liam@imtl.co.uk',
        phone: '07708219473',
        message: 'testing'
      })
    }).then(response => {
      
    });

    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <div>Thank you!</div>
        <div>We'll be in touch soon.</div>
      </>
    );
  }

  return <Container>
    <Text>Get A Free Quote</Text>
    <Text>info@insubaudio.co.uk</Text>
    <Form
      onSubmit={handleSubmit}
    >
      <NameContainer>
        <NameInput name="name" placeholder="Name" />
      </NameContainer>
      <PhoneAndEmailContainer>
        <PhoneContainer><PhoneInput name="phone" placeholder="Phone" /></PhoneContainer>
        <EmailContainer><EmailInput name="email" placeholder="Email" /></EmailContainer>
      </PhoneAndEmailContainer>
      <MessageContainer>
        <MessageBox
          placeholder="Your message"
          name="message"
          style={{}}
        />
      </MessageContainer>
      <SubmitContainer>
        <Submit
          type="submit"
        >
          Request a Quote
        </Submit>
      </SubmitContainer>
    </Form>
  </Container>;
}